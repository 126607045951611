<template>
  <page>
    <template slot="headerInfo">
      <el-button
        v-auth="'supplierPurchase:downNotShippedGoods'"
        :disabled="!tableData.length"
        @click="exportData"
        type="primary"
      >
        导出订单信息
      </el-button>
    </template>

    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="商品名称" prop="goodsName">
          <el-input v-model="params.goodsName" placeholder="商品名称" />
        </el-form-item>
        <el-form-item label="条形码" prop="barNo">
          <el-input v-model="params.barNo" placeholder="条形码" />
        </el-form-item>
        <el-form-item label="商品一级分类" prop="clazzId1">
          <el-select v-model="params.clazzId1" clearable>
            <el-option
              v-for="item in typeList1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单时间" prop="date">
          <el-date-picker
            v-model="params.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table :data="tableData" v-loading="loading" height="100%">
        <el-table-column
          show-overflow-tooltip
          min-width="100"
          prop="goodsName"
          label="商品名称"
        />
        <el-table-column
          show-overflow-tooltip
          min-width="100"
          prop="barNo"
          label="条形码"
        />

        <el-table-column
          show-overflow-tooltip
          prop="goodsNum"
          label="未发货数量"
        />
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      isDetail: false,
      visible: false,
      detailVisible: false,
      currentItem: null,
      loading: false,
      tableData: [],
      params: {
        limit: 20,
        page: 1,
        clazzId1: '',
        goodsName: '',
        barNo: '',
        date: []
      },
      typeList1: [],
      totalCount: 0
    }
  },
  created() {
    this.getTypeList()
  },
  methods: {
    async getTypeList() {
      try {
        const res = await this.$api.classification.getClazzList({
          limit: 1000,
          page: 1
        })
        this.typeList1 = (res.clazzList.list || []).map(item => {
          return {
            label: item.clazzName,
            value: item.id
          }
        })
      } catch (err) {}
    },

    async getData(query) {
      try {
        this.loading = true
        let params = { ...this.params }
        if (query) {
          params.limit = 20
          params.page = 1
        }
        for (let i in params) {
          if (params[i] === '') params[i] = undefined
        }
        if (params.date && params.date.length) {
          params.beginDate = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD'
          )
          params.endDate = this.$utils.dateFormat(params.date[1], 'YYYY-MM-DD')
        } else {
          params.beginDate = undefined
          params.endDate = undefined
        }
        const { date, ...sendData } = params
        const res = await this.$api.orderList.getOrderQueryList(sendData)
        this.tableData = ((res.page && res.page.list) || []).map(item => {
          return item
        })
        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    detailHandler(row) {
      this.currentItem = row
      this.detailVisible = true
    },

    async exportData() {
      try {
        this.loading = true

        const params = { ...this.params }

        if (params.date && params.date.length) {
          params.beginDate = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD'
          )
          params.endDate = this.$utils.dateFormat(params.date[1], 'YYYY-MM-DD')
        } else {
          params.beginDate = undefined
          params.endDate = undefined
        }

        await this.$api.orderList.exportOrderQueryList({
          beginDate: params.beginDate,
          endDate: params.endDate,
          goodsName: params.goodsName || undefined,
          clazzId1: params.clazzId1 || undefined,
          barNo: params.barNo || undefined
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
